<template>
  <div>
    <h3>Buy Voucher</h3>
    <form>
      <div class="form-group">
        <label for="shop">Select Shop</label>
        <select
            class="form-control"
            id="shop"
            @change="getVouchers($event)"
            v-model="shop"
        >
          <option v-for="shop in shops" :key="shop.id" :value="shop">{{shop.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="voucher">Select Voucher</label>
        <select
            class="form-control"
            id="voucher"
            @change="selectedVoucher($event)"
            v-model="voucher"
        >
          <option
              v-for="option in vouchers"
              :key="option.id"
              :value="option"
          >{{ option.name + " " + option.amount }}</option
          >
        </select>
      </div>
      <div class="form-group">
        <label for="price">Price of Voucher</label>
        <input
            disabled
            type="text"
            class="form-control"
            id="price"
            v-model="voucherPrice"
        />
      </div>
      <div class="form-group">
        <label for="name">Full Name of Receiver</label>
        <input
            type="text"
            class="form-control"
            id="name"
            v-model="form.full_name"
        />
      </div>
      <div class="form-group">
        <label for="phone">Phone Number of Receiver</label>
        <input
            type="phone"
            class="form-control"
            id="phone"
            placeholder="+263777123123"
            v-model="form.mobile"
        />
      </div>
      <div class="form-group">
        <label for="email">Email address of Receiver</label>
        <small id="emailHelp" class="form-text text-muted">(Optional)</small>
        <input
            type="email"
            class="form-control"
            id="email"
            aria-describedby="emailHelp"
            v-model="form.email"
        />
      </div>
      <div class="form-group form-check">
        <input
            required
            type="checkbox"
            class="form-check-input"
            id="agree_to_terms"
            v-model="agreeToTerms"
        />
        <label class="form-check-label" for="agree_to_terms">
          I have Read the Terms and Agreements</label
        >
      </div>

      <div class="justify-content-between mb-4 mt-4">
        <div>
          <button
              v-if="auth.isAuthenticated"
              :disabled="isLoading"
              type="submit"
              class="ps-btn btn-sm"
              @click.prevent="addToCart()"
          >
            <i v-if="!isLoading" class="icon-cart-add"></i>
            {{ isLoading ? "Processing..." : "Add to cart" }}
            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
            <div
                v-if="isLoading"
                class="spinner-border text-light"
                role="status"
            ></div>
          </button>
          <router-link v-else :to="{ name: 'login' }">
            <button type="submit" class="ps-btn btn-sm">
              <i v-if="!isLoading" class="icon-cart-add"></i>
              Add to Cart
            </button>
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BuyVoucher",
  data() {
    return {
      form: {
        voucher_id: "",
        mobile: "",
        full_name: "",
        voucher_price: "",
        email: "",
      },
      vouchers: [],
      isLoading: false,
      voucherShop: null,
      shops: [],
      shop: null,
      voucher: {},
      voucherPrice: null,
      filteredVouchers: [],
      agreeToTerms: false,
      errors: null
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {
    this.getShops();
    // this.getVouchers();
  },
  methods: {

    getShops() {
      axios.get("/shops/list?with_vouchers=1").then((response) => {
        this.shops = response.data
      });
    },

    getVouchers() {
      let params = [
          'status=active',
          `shop=${this.shop.id}`
      ]
      axios.get(`vouchers?${params.join('&')}`).then((response) => {
        this.vouchers = response.data.data;
      });
    },

    selectedVoucher($event) {
      if (this.voucher) {
        this.voucherPrice = this.voucher.amount;
        this.form.voucher_id = this.voucher.id
        this.form.voucher_price = this.voucher.amount
      }
    },

    createVoucherRequest() {
      // this.isLoading = true;
      //
      // axios
      //   .post("/vouchers/buy", this.voucher)
      //   .then((response) => {
      //     if (response.data.id) {
      //       this.addToCart(response.data.id);
      //     }
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     this.$toasted.error("Voucher Creation failed, please try again");
      //     this.isLoading = false;
      //   });
    },

    addToCart() {
      const price = this.voucher.amount;
      const quantity = 1;
      const total = price * quantity

      const product = {
        id: this.voucher.id,
        name: this.voucher.name,
        quantity: quantity,
        price: this.voucher.amount,
        total: total,
        type: "voucher",
        shop: 2,
        props: {...this.form, description: this.voucher.description}
      };

      console.log("Voucher");
      console.log(product);
      this.$store.dispatch("cart/addToCart", {
        product: product,
        quantity: 1,
      });

      this.$toasted.success("Voucher added to cart");
    },
  },
};
</script>
